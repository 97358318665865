import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';

const pool = new CognitoUserPool({
  UserPoolId: 'sa-east-1_81dIzrFFU',
  ClientId: '12rg9i8fotah5dt9ms8mfmn2mq',
  Storage: new CookieStorage({
    secure: true,
    sameSite: 'none',
    domain: window.location.host.replace(':9000', '').replace(':9001', ''),
    // domain: window.location.host.replace('agent.', '').replace('agent-dev.', '').replace(':9000', '').replace(':9001', ''),
  }),
});

export default pool;

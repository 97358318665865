import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
  CookieStorage,
} from 'amazon-cognito-identity-js';
import pool from '../pool';
import sessionHelper from '../utils/sessionHelper';
import { deleteCognitoCookiesWithPrefix } from '../utils/cookieHelper';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { navigateToUrl } from 'single-spa';

const getSession = async (): Promise<CognitoUserSession> => {
  return new Promise(async (resolve, reject) => {
    const user = pool.getCurrentUser();
    // user.signOut
    if (user) {
      user.getSession(async (err, session: CognitoUserSession) => {
        if (err || !session) {
          console.log('getSession function --> cognitoUser', user);

          console.log('user.signOut');
          await user.signOut();
          console.log('user.signOut 2');
          
          deleteCognitoCookiesWithPrefix('CognitoIdentityServiceProvider.');

          reject(err);
        } else {
          resolve(sessionHelper.checkSessionValidity(user, session));
        }
      });
    } else {
      console.log('user.signOut error');
      // await user.signOut();
      //deleteCognitoCookiesWithPrefix('CognitoIdentityServiceProvider.');

      reject('Get session rejected: No user');
    }
  });
};

const getTokens = (code: string) => {
  const redirectUrl = window.location.origin;
  console.log('getTokens function');

  fetch('https://login.trii.app/oauth2/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: '12rg9i8fotah5dt9ms8mfmn2mq',
      redirect_uri: `${redirectUrl}/externalauthentication/callback`,
      code: code,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log('getTokens function --> data', data);

      const decodedToken = jwtDecode<JwtPayload & { email: string }>(data.id_token);
      const username = decodedToken.email;
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: pool,
        Storage: new CookieStorage({
          secure: true,
          domain: window.location.host.replace(':9000', '').replace(':9001', ''),
          // domain: window.location.host.replace('agent.', '').replace('agent-dev.', '').replace(':9000', '').replace(':9001', ''),
        }),
      });

      console.log('getTokens function --> cognitoUser', cognitoUser);
      const idToken = new CognitoIdToken({ IdToken: data.id_token });
      const accessToken = new CognitoAccessToken({ AccessToken: data.access_token });
      const refreshToken = new CognitoRefreshToken({
        RefreshToken: data.refresh_token,
      });
      const sessionData = {
        IdToken: idToken,
        AccessToken: accessToken,
        RefreshToken: refreshToken,
      };
      console.log('getTokens function --> sessionData', sessionData);

      cognitoUser.setSignInUserSession(new CognitoUserSession(sessionData));

      console.log(
        'getTokens function --> success  setting sign in user session into the cognitoUser',
        cognitoUser
      );
      // navigateToUrl('/');
      window.location.href = redirectUrl;
    })
    .catch((error) => {
      console.error('Error al obtener los tokens:', error);
    });
};

const sessionService = { getSession, getTokens };

export default sessionService;

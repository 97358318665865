import { navigateToUrl } from 'single-spa';

const VALID_ROUTES = [
  '/a/settings',
  '/a/contacts',
  '/a/conversations',
  '/a/marketing',
  '/a/calendar',
  '/a/calls',
  '/a/tickets',
  '/a/repair',
  '/a/debtcollection',
  '/a/chat',
  '/view/contacts',
  '/externalauthentication/callback',
];

/**
 * Extracts the 'code' parameter value from the current URL's query string.
 * @returns {string | null} The value of the 'code' parameter or null if not present.
 */
const getCodeFromUrl = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  console.log('Current URL:', window.location.href); // Muestra la URL completa
  console.log('Pathname:', window.location.pathname); // Muestra el pathname de la URL
  console.log('Search Params:', window.location.search); // Muestra la cadena de consulta completa
  console.log('Extracted code:', code);
  return code;
};

const relocateOnInvalidRoute = () => {
  const pathname = window.location.pathname;

  if (
    !VALID_ROUTES.some((route) => pathname.startsWith(route)) &&
    pathname !== '/'
  ) {
    navigateToUrl('/');
  }
};

const rootHelper = {
  getCodeFromUrl,
  relocateOnInvalidRoute,
};

export default rootHelper;

import { getToken } from 'firebase/messaging';
import { messaging } from '../firebase.js';

async function getFirebaseToken() {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        'BA90LIoCDtbVHh8MCIn9f1dAMZTZtxG-b_IJGJe9Gcl49ZcQFoaPGNrc5h0sJmVz4T1cRY7XPmEaPTnc6nyiF8w',
    });
    console.log('Token: ', token);
    return token;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export default { getFirebaseToken };

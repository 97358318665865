const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

// Función para eliminar todas las cookies que empiezan con un prefijo específico
export const deleteCognitoCookiesWithPrefix = (prefix: string) => {
  console.log('document.cookie', document.cookie);
  const cookies = document.cookie.split(';');
  console.log('cookies', cookies);
  cookies.forEach((cookie) => {
    const cookieName = cookie.split('=')[0].trim();
    console.log('Deleting cookieName: ', cookieName);
    if (cookieName.startsWith(prefix)) {
      deleteCookie(cookieName);
    }
  });
};

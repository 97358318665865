import moduleHelper from './moduleHelper';

const handleNewEvent = (event: MessageEvent) => {
  const eventName = event.data.eventKey;
  const moduleName = moduleHelper.getModuleNameForEvent(eventName);

  if (
    !moduleHelper.doesUrlContainModule(eventName) &&
    document.visibilityState !== 'visible'
  ) {
    localStorage.setItem(`newevents_${moduleName}`, 'true');
  } else {
    setTimeout(() => {
      localStorage.setItem(`newevents_${moduleName}`, 'false');
    }, 500);
  }
};

const moduleEventHelper = {
  handleNewEvent,
};

export default moduleEventHelper;

// Spa
import { registerApplication, start } from 'single-spa';
import { constructApplications, constructRoutes } from 'single-spa-layout';
// Utils
import moduleHelper from './utils/moduleHelper';
import spinnerHelper from './utils/spinnerHelper';
import themeHelper from './utils/themeHelper';
import notificationHelper from './utils/notificationHelper';
import api from './utils/api';
import { messaging } from './firebase';

const pathSegments = window.location.pathname.split('/');

const firstSegment = pathSegments[0] === '' ? pathSegments[1] : pathSegments[0];

const elementId =
  firstSegment === 'view' ? '#single-spa-layout-view' : '#single-spa-layout-a';
// Routes
const routes = constructRoutes(document.querySelector(elementId));

navigator.serviceWorker.addEventListener('message', (event) => {
  console.log('Received message from service worker:', event.data);
  const eventName = event.data.eventKey;
  const moduleName = moduleHelper.getModuleNameForEvent(eventName);

  if (
    !moduleHelper.doesUrlContainModule(eventName) &&
    document.visibilityState !== 'visible'
  ) {
    localStorage.setItem(`newevents_${moduleName}`, 'true');
  } else {
    setTimeout(() => {
      localStorage.setItem(`newevents_${moduleName}`, 'false');
    }, 500);
  }
});

const applications = constructApplications({
  routes,
  //@ts-ignore
  loadApp({ name }) {
    return Promise.resolve()
      .then(() => {
        spinnerHelper.show();

        return System.import(name);
      })
      .then((app) => {
        spinnerHelper.hide();
        return app;
      });
  },
});

const setApplications = async () => {
  try {
    const { pool, userInfo, spaceInfo, jwt } = await api();

    if (userInfo) themeHelper.setTheme(userInfo.theme);
    console.log('Messaging: ', messaging);
    applications.forEach((app) => {
      app.customProps = {
        spaceInfo,
        userInfo,
        pool,
        firebaseMessaging: messaging,
      };

      registerApplication(app);
    });

    spinnerHelper.hide();
  } catch (e) {
    console.error(e);
    window.location.href = `https://account.trii.app/signin/?redirect=${window.location.href}`;
  }
};

const requestNotificationOnInteraction = () => {
  console.log("Requesting notification permission on user's interaction.");
  notificationHelper.handleNotificationPermission();

  document.removeEventListener('click', requestNotificationOnInteraction);
  document.removeEventListener('keydown', requestNotificationOnInteraction);
};

document.addEventListener('click', requestNotificationOnInteraction);
document.addEventListener('keydown', requestNotificationOnInteraction);

setApplications();
start();

// Service
import deviceService from '../service/deviceService';
import api from './api';
// Helper
import firebaseHelper from './firebaseHelper';

async function saveDevice(jwt: string) {
  const token = await firebaseHelper.getFirebaseToken();

  await deviceService.add(token, jwt);
}

async function getPermission() {
  if (!('Notification' in window)) {
    console.log('No notification in window');

    return false;
  }

  const permission = await Notification.requestPermission();

  if (permission === 'granted') {
    return true;
  } else if (permission === 'denied') {
    console.log('You denied the notification');
    return false;
  }
}

const handleNotificationPermission = async () => {
  try {
    const permissionGranted = await getPermission();
    if (permissionGranted) {
      const { jwt } = await api();
      await saveDevice(jwt);
    }
    console.log('Notifications permission granted or handled.');
  } catch (error) {
    console.error('Error while requesting notification permission', error);
  }
};

export default {
  getPermission,
  saveDevice,
  handleNotificationPermission,
};

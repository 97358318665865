// Spa
import { registerApplication, start } from 'single-spa';
import { constructApplications, constructRoutes } from 'single-spa-layout';
// Utils
import spinnerHelper from './utils/spinnerHelper';
import themeHelper from './utils/themeHelper';
import notificationHelper from './utils/notificationHelper';
import api from './utils/api';
import { messaging } from './firebase';
import rootHelper from './utils/rootHelper';
import sessionService from './service/sessionService';
import moduleEventHelper from './utils/moduleEventHelper';

const pathSegments = window.location.pathname.split('/');

const firstSegment = pathSegments[0] === '' ? pathSegments[1] : pathSegments[0];

const elementId =
  firstSegment === 'view' ? '#single-spa-layout-view' : '#single-spa-layout-a';
// Routes
const routes = constructRoutes(document.querySelector(elementId));

navigator.serviceWorker.addEventListener(
  'message',
  moduleEventHelper.handleNewEvent
);

const applications = constructApplications({
  routes,
  //@ts-ignore
  loadApp({ name }) {
    return Promise.resolve()
      .then(() => {
        spinnerHelper.show();

        return System.import(name);
      })
      .then((app) => {
        spinnerHelper.hide();
        return app;
      });
  },
});

const setApplications = async () => {
  try {
    const { pool, userInfo, spaceInfo, jwt } = await api();

    if (userInfo) themeHelper.setTheme(userInfo.theme);

    applications.forEach((app) => {
      app.customProps = {
        spaceInfo,
        userInfo,
        pool,
        firebaseMessaging: messaging,
      };

      registerApplication(app);
    });

    spinnerHelper.hide();
  } catch (e) {
    console.error(e);
    const code = rootHelper.getCodeFromUrl();
    console.log('setApplications -> code', code);
    if (code) {
      console.log('setApplications -> code detected path');
      sessionService.getTokens(code);
    } else {
      console.log('setApplications -> code NOT detected path');
      const domain = `https://login.trii.app`;
      const clientId = '12rg9i8fotah5dt9ms8mfmn2mq';
      const redirectUrl = window.location.origin;
      const loginUrl = `${domain}/login?client_id=${clientId}&response_type=code&scope=email+openid+profile&redirect_uri=${redirectUrl}/externalauthentication/callback`;

      window.location.href = loginUrl;
    }
  }
};

const requestNotificationOnInteraction = () => {
  notificationHelper.handleNotificationPermission();

  document.removeEventListener('click', requestNotificationOnInteraction);
  document.removeEventListener('keydown', requestNotificationOnInteraction);
};

window.addEventListener(
  'single-spa:routing-event',
  rootHelper.relocateOnInvalidRoute
);

document.addEventListener('click', requestNotificationOnInteraction);
document.addEventListener('keydown', requestNotificationOnInteraction);

setApplications();
start();
